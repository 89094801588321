import React, { useState, useEffect } from 'react';
import { Link } from 'gatsby';
import { useLocation } from '@reach/router';
import LogoAds75 from '../images/logo-ads-75.png';

const MenuLink = ({ to, ...rest }) => {
  const  { pathname } = useLocation();
  let classes = 'pb-0.5';

  if (to === pathname) {
    classes += ' font-semibold border-b-2 border-blue-800';
  }

  return <Link to={to} className={classes} {...rest} />;
}

export default function Header() {
  // Local state
  const [hidden, setHidden] = useState(true);

  // Close menu
  useEffect(() => {
    if (!hidden) {
      const handleHide = () => setHidden(true);
      document.addEventListener('click', handleHide);
      return () => document.removeEventListener('click', handleHide);
    }
  }, [hidden]);

  return (
    <div className="container mx-auto border-b border-slate-200">
      <nav className="flex justify-center md:justify-start items-center py-4">
        <button type="button" onClick={() => setHidden(false)} className={`md:hidden left-4 absolute ${hidden ? 'menu-hide' : ''}`}>
          <img className="h-8" src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEAAAABACAMAAACdt4HsAAAABGdBTUEAALGPC/xhBQAAAAFzUkdCAK7OHOkAAAAgY0hSTQAAeiYAAICEAAD6AAAAgOgAAHUwAADqYAAAOpgAABdwnLpRPAAAADNQTFRFAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAA////o7tg2wAAAA90Uk5TAAETHhJgz+HNWMvMyhFS/Pi60wAAAAFiS0dEEJWyDSwAAAAJcEhZcwAA7DgAAOw4AXEryjgAAABVSURBVFjD7dM5DoAwDETRJBhwwpL73xbROeUICoT+6z2yLU1KAP4ll0lkeQiYl1XkNc4X77JmIcA2PWA/4grV1RPaOT7RHj4RuNFG2oivoI20EcAbLphtHpqUEZiiAAAAJXRFWHRkYXRlOmNyZWF0ZQAyMDE5LTA3LTAzVDE2OjEwOjIwKzAxOjAwsRmjxQAAACV0RVh0ZGF0ZTptb2RpZnkAMjAxOS0wNy0wM1QxNjoxMDoyMCswMTowMMBEG3kAAABGdEVYdHNvZnR3YXJlAEltYWdlTWFnaWNrIDYuNy44LTkgMjAxOS0wMi0wMSBRMTYgaHR0cDovL3d3dy5pbWFnZW1hZ2ljay5vcmdBe+LIAAAAGHRFWHRUaHVtYjo6RG9jdW1lbnQ6OlBhZ2VzADGn/7svAAAAGHRFWHRUaHVtYjo6SW1hZ2U6OmhlaWdodAA1MTLA0FBRAAAAF3RFWHRUaHVtYjo6SW1hZ2U6OldpZHRoADUxMhx8A9wAAAAZdEVYdFRodW1iOjpNaW1ldHlwZQBpbWFnZS9wbmc/slZOAAAAF3RFWHRUaHVtYjo6TVRpbWUAMTU2MjE2NjYyMD2+POEAAAATdEVYdFRodW1iOjpTaXplADMuMDNLQkLxSz52AAAAZ3RFWHRUaHVtYjo6VVJJAGZpbGU6Ly8uL3VwbG9hZHMvNTYvcHl3bFdUQi8xOTkzL2J1cmdlcl9saW5lX2xpc3RfbWVudV9uYXZfbmF2aWdhdGlvbl9vcHRpb25faWNvbl8xMjMyMzEucG5nLeJOSAAAAABJRU5ErkJggg==" />
        </button>
        <Link to="/">
          <img src={LogoAds75} className="h-12 w-auto" />
        </Link>
        <div className="hidden md:flex flex-grow justify-end space-x-6 text-slate-700">
          <MenuLink to="/">Accueil</MenuLink>
          <MenuLink to="/prestations">Prestations</MenuLink>
          <MenuLink to="/marques">Marques</MenuLink>
          <MenuLink to="/contact">Contact</MenuLink>
        </div>
      </nav>
      {hidden !== true && (
        <div className="absolute bg-white inset-0 z-50">
          <div className="px-4 py-6">
            <button type="button" onClick={() => setHidden(true)} className="menu-show">
              <img className="h-8" src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEAAAABACAMAAACdt4HsAAAABGdBTUEAALGPC/xhBQAAAAFzUkdCAK7OHOkAAAAgY0hSTQAAeiYAAICEAAD6AAAAgOgAAHUwAADqYAAAOpgAABdwnLpRPAAAAGNQTFRFAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAA////ZtZSzAAAAB90Uk5TAAETEmDPzV4CA2RY7WNp78zLyhHsYl1q7vDx8mVZU2lOwSgAAAABYktHRCCzaz2AAAAACXBIWXMAAOw4AADsOAFxK8o4AAABEElEQVRYw+2WyRaCMAxFGaSgUMUJFNT+/18qCA5AhoaVnmYb7qWU9p14nitX/1d+EPqznllEKk5wfrmKVAo1A2VMplFDst4Yk4dAN9wawtDyZreH+nGGG578AfwELzmiho4vsCViBgaPGlg8YmDyoIHNAwYLftJgxU8YLPmRwZofGAT8l0HEfxiE/NtQnmR8bzhXUr43yPmHQTevNxUVc7CgrBtBraULaPefSjmKbzdRZOj+f6wzmeF1foicJHkyaUleZBicf2vD6P5YGibun5Vh8v5aGID7zzaA+cE0IPnDMqD5xTAQ+UcbLkR+doYY6jczEmd+QGckzvwAz0hXlafUmFeo/AZ2Zw+arlz9bt0BaSQxO4SQK/oAAAAldEVYdGRhdGU6Y3JlYXRlADIwMTktMDEtMjhUMTE6NDA6NTErMDE6MDACtLsbAAAAJXRFWHRkYXRlOm1vZGlmeQAyMDE5LTAxLTI4VDExOjQwOjUxKzAxOjAwc+kDpwAAAEZ0RVh0c29mdHdhcmUASW1hZ2VNYWdpY2sgNi43LjgtOSAyMDE2LTA2LTE2IFExNiBodHRwOi8vd3d3LmltYWdlbWFnaWNrLm9yZ+a/NLYAAAAYdEVYdFRodW1iOjpEb2N1bWVudDo6UGFnZXMAMaf/uy8AAAAYdEVYdFRodW1iOjpJbWFnZTo6aGVpZ2h0ADUxMsDQUFEAAAAXdEVYdFRodW1iOjpJbWFnZTo6V2lkdGgANTEyHHwD3AAAABl0RVh0VGh1bWI6Ok1pbWV0eXBlAGltYWdlL3BuZz+yVk4AAAAXdEVYdFRodW1iOjpNVGltZQAxNTQ4NjcyMDUxSCcwbgAAABN0RVh0VGh1bWI6OlNpemUAMy42OUtCQhG2K7IAAABYdEVYdFRodW1iOjpVUkkAZmlsZTovLy4vdXBsb2Fkcy81Ni9nb1ZmcG12LzE3NjkvNDExNTIzMC1jYW5jZWwtY2xvc2UtY3Jvc3MtZGVsZXRlXzExNDA0OC5wbmcw/qAMAAAAAElFTkSuQmCC" />
            </button>
            <div className="mt-4 ml-8">
              <Link to="/" className="flex items-center font-semibold py-1 mb-2">
                Accueil
              </Link>
              <Link to="/prestations" className="flex items-center font-semibold py-1 mb-2">
                Prestations
              </Link>
              <Link to="/marques" className="flex items-center font-semibold py-1 mb-2">
                Marques
              </Link>
              <Link to="/contact" className="flex items-center font-semibold py-1 mb-2">
                Contact
              </Link>
            </div>
          </div>
        </div>
      )}
    </div>
  )
}
