import React from 'react';
import { Link } from 'gatsby';

export default function Footer() {
  return (
    <div className="bg-slate-900 text-slate-300 pt-20 px-8 md:px-0">
      <div className="container md:mx-auto">
        <div className="flex-col md:flex-row flex justify-between">
          <div className="mb-8 md:mb-0">
            <p className="mb-2"><strong>Serrurerie à Paris – Charonne.</strong></p>
            <p className="mb-6">Intervention 24h/24 7j/j<br />dans les secteurs Paris et Ile de France.</p>
            <p className="mb-2">
              <a href="tel://+33180506356">01 80 50 63 56</a>
            </p>
          </div>
          <div className="mb-8 md:mb-0">
            <p className="mb-2"><strong>Serrurerie Paris - Bagnolet - Vincennes - Montreuil</strong></p>
            <p className="mb-2"><Link to="/">Accueil</Link></p>
            <p className="mb-2"><Link to="/prestations">Nos prestations</Link></p>
            <p className="mb-2"><Link to="/marques">Nos marques</Link></p>
            <p className="mb-2"><Link to="/contact">Nous contacter</Link></p>
          </div>
          <div className="mb-8 md:mb-0">
            <p className="mb-2"><strong>Adresse</strong></p>
            <p className="mb-2">
              Serruerie A.D.S 75<br />
              146 Boulevard de Charonne<br />
              75020 Paris
            </p>
          </div>
          <div></div>
        </div>
        <div className="mt-8 border-t border-slate-700 py-6 text-sm">© Tous droits réservés 2022 – ADS 75</div>
      </div>
    </div>
  )
}
