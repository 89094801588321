import React from 'react';
import { Helmet } from 'react-helmet';
import Header from './Header';
import Footer from './Footer';

export default function Main({ children }) {
  return (
    <main className="fade-in text-slate-900 text-lg md:text-base">
      <Helmet>
        <title>ADS 75 - Serrurerie Charonne - Paris, Bagnolet, Vincennes, Montreuil</title>
        <meta name="description" content="Artisan serrurier depuis plus de 20 ans. Contactez-nous 24h/24 pour une ouverture de porte, double de clé, installation de porte blindée." />
      </Helmet>
      <Header />
      {children}
      <Footer />
    </main>
  )
}
